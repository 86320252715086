@import "./app/shared/styles/variables";

// disable button
.disable-background-color {
  background-color: $lightgray;
}

// primary button start

.primary-btn{
  padding: 10px 17px;
}

.btn-p-1 {
  background: $lightblue;
  box-shadow: 0px 2px 12px $lighterblue;
  border-radius: 5px;
  border-color: $lightblue;
  color: $white;
}

.red-color{
  color: #F85050  ;
}

.btn-p-1:hover {
  background: $blue;
  border-color: $blue;
  color: $white;
}

.btn-p-2 {
  background: $skyblue;
  border-color: $skyblue;
  border-radius: 5px;
  color: $white;
}

.btn-p-2:hover {
  background: $darkblue;
  border-color: $darkblue;
  color: $white;
}

.btn-p-3 {
  background: $white;
  border-color: #E3ECF1;
  border-radius: 10px;
  color: $white;
}

.btn-p-3:hover {
  background: $white;
  border-color: #E3ECF1;
  color: $white;
}

.btn-p-danger {
    background: $lightred;
    border-color: $lightred;
    border-radius: 5px;
    color: $white;
}

.btn-p-danger:hover {
    background: $darkred;
    border-color: $darkred;
    color: $white;
}

// primary button end

// standard button padding
.standard-btn {
    padding: 12px 17px;
}


// secondary button start

.btn-skyblue-s-1 {
    box-sizing: border-box;
    border: 1px solid $skyblue;
    border-radius: 5px;
    color: $skyblue;
    background: #ffffff;
}
  
.btn-skyblue-s-1:hover {
    background: $skyblue;
    color: $white;
}

.btn-green-s-1 {
    box-sizing: border-box;
    border: 1px solid $lightgreen;
    border-radius: 5px;
    color: $lightgreen;
}
  
.btn-green-s-1:hover {
    background: $lightgreen;
    color: $white;
}

.btn-red-s-1 {
  box-sizing: border-box;
  border: 1px solid $lightred;
  border-radius: 5px;
  color: $lightred;
}

.btn-red-s-1:hover {
  background: $lightred;
  color: $white;
}


.add-btn-s {
    background: $lightblack;
    border-color: $darkblack;
    border-radius: 3px;
    color: $white;
}

.add-btn-s:hover {
    background: $darkblack;
    border-color: $darkblack;
    border-radius: 3px;
    color: $white;
}

.skyblue-btn-s {
    background: rgba(3, 192, 252, 0.17);
    border-color: rgba(3, 192, 252, 0.17);
    border-radius: 3px;
    color: $skyblue;
    padding: 12px 17px;
}

.skyblue-btn-s:hover {
    background: $skyblue;
    border-color: $skyblue;
    border-radius: 3px;
    color: $white;
}

.btn-s-danger {
  background: rgba(248, 80, 80, 0.1);
  border-radius: 5px;
  color: $lightred;
}

.btn-s-danger:hover {
  background: $lightred;
  border-color: $lightred;
  color: $white;
}


.btn-s-warning {
  background: $lightorange;
  background: $lightorange;
  border-radius: 5px;
  color: $white;
}

.btn-s-warning:hover {
  background: $orange;
  border-color: $orange;
  color: $white;
}
// secondary button start

// link btn start

.add-btn-plain-link {
    font-family: "Poppins";
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $skyblue !important;
  }
  
.remove-btn-plain-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $lightred;
}

  // link btn end

// pagination style
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 3;
  background-color: $skyblue;
  border-color: $skyblue;
}

.pagination > .active > a {
  color: $white;
  background-color: $skyblue !important;
  border: solid 1px $skyblue !important;
}

.pagination {
  width: 60% !important;
  display: flex;
  justify-content: center;
}




.filter-radius {
    border-radius: 0px 9px 9px 0px;
}
mailbox-list,
mailbox-details{
width: -webkit-fill-available;
}


.blink {
  animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}