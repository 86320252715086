// Colores principales
$color-principal: #1B3766;

// Tamaños dispositivos
$mobile: 400px;
$tablet: 768px;
$desktop: 960px;

$lightblue: #0099FF;
$blue: #006ED3;
$lighterblue: #8BD1FF;

$skyblue: #03C0FC;
$darkblue: #00A1D3;





$lightgray: #B6C9D4;

$lightred: #F85050;
$darkred: #B43B3B;

$lightgreen: #22BC31;
$green: #128C1E;

$lighterblack: #3A3A3A;
$lightblack: #364F6B;
$darkblack: #1E2F42;
$darkerblack: #1F1F1F;

$lightorange: #FBC70E;
$orange: #FF9F10;


$white: #FFFFFF;


$lightgray: #ECF1F4;