*::-webkit-scrollbar {
    width: 10px;
}

@import '~quill/dist/quill.snow.css';

/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
*::-webkit-scrollbar-thumb {
    background: #b5c9d4;
    border-radius: 20px;
}

*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
}

// @tailwind base;
// @tailwind components;
@tailwind utilities;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-osmos-primary: mat-palette($mat-indigo);
$front-osmos-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$front-osmos-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$front-osmos-ligth-theme: mat-light-theme(
    (
        color: (
            primary: $front-osmos-primary,
            accent: $front-osmos-accent,
            warn: $front-osmos-warn,
        ),
    )
);
$front-osmos-dark-theme: mat-dark-theme(
    (
        color: (
            primary: $front-osmos-primary,
            accent: $front-osmos-accent,
            warn: $front-osmos-warn,
        ),
    )
);
.nav-link{
    padding: 10px !important;
}

.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
    font: 400 14px/21px "Poppins", "Helvetica Neue", sans-serif;
    color: #113D55;
}
.responsive-font{
    font: 400 0.875rem/1.313rem "Poppins", "Helvetica Neue", sans-serif;
    color: #113D55;
}
.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2, .mat-typography .h2 {
    font: 600 22px/33px "Poppins", "Helvetica Neue", sans-serif;
}
// .dropdown-menu {
//     font-size: 15px !important;
// }

// h1,
// h2,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// div,
// span,
// label {
//     font-family: "Poppins", sans-serif !important;
// }

h1,
h2,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

f-500 {
    font-weight: 500;
}
.f-poppins {
    font-family: "Poppins", "Helvetica Neue", "sans-serif";
}

// body,
// .mat-body,
// .mat-body-1,
// .mat-typography {
//     font-size: 16px;
//     font-family: "Poppins", sans-serif;
//     font: 400 14px/20px "Poppins", "Helvetica Neue", sans-serif
// }

// body {
//     background: inherit;
// }

// this need to add to fix horizontal scroll 
app-home,
app-profile,
app-tasks,
app-items,
app-quote-template,
app-quote-listing,
app-new-quote,
app-system-users,
app-business-profile,
app-contact-profile,
app-contacts,
app-organization,
app-lead,
app-lead-form,
app-form-builder,
app-lead-count,
all-leads,
app-add-organization,
app-edit-contact,
app-email-template,
app-email-listing,
app-meetings-schedules,
app-mailbox,
app-outgoing-mail-server-list,
app-new-users-create,
app-new-role-create,
app-new-team-create,
edit-view-role,
app-analytics-sale,
all-leads-vertical,
app-imap-error {
    flex-shrink: 1 !important;
    flex: 1 0 0%;
    padding: 0 !important;
}

app-contacts, app-email-template {
    overflow-x: auto !important;
}
app-lead-form,app-form-builder{
    overflow-x: hidden !important;
}
app-lead{
    overflow-x: hidden !important;
}

.dark-theme {
    @include angular-material-theme($front-osmos-dark-theme);

    .body {
        background: #191919;
    }

    .navbar {
        background: #131313;
    }

    .p-h,
    .accordion-button::after {
        color: #767676;
    }

    .ico-h,
    .text-h {
        color: #9b9b9b;
    }

    .add-more-card {
        background: rgba(17, 255, 53, 0.125) !important;
    }

    .col-h {
        background: #131313;
        border-top: 3px solid #03c0fc;
    }

    .bg-col-h {
        background: #131313;
    }

    .cnt-collapse {
        background: #191919;
    }

    .ico-h {
        background: #2c2c2c;
    }

    .switch-button-checkbox:checked+.switch-button-label .switch-button-label-span2 {
        color: #fff;
    }

    .switch-button-checkbox+.switch-button-label .switch-button-label-span {
        position: relative;
        color: #fff;
    }

    .switch-button-checkbox:checked+.switch-button-label .switch-button-label-span {
        color: #767676;
    }

    .switch-button-checkbox+.switch-button-label .switch-button-label-span2 {
        color: #767676;
    }

    .switch-button,
    .cnt-dashboard {
        background: #1f1f1f;
        border: none;
    }

    .dropdown-menu {
        background: #1f1f1f;
        border: none;
    }

    .dropdown-1:after {
        border: solid transparent;
        border-bottom-color: #1f1f1f;
        border-width: 13px;
        margin-right: 0px;
    }

    .dropdown-1:before {
        border: solid transparent;
    }

    .bg-0::after {
        border-bottom-color: #191919;
    }

    .cnt-ico {
        background: #2c2c2c;
        color: #9b9b9b;
    }

    .cnt-sms {
        border-left: 1px solid #1e1d1d;
    }

    .col-h-2 {
        background: #131313;
        border: 1px solid #1e1d1d;
    }

    .bg-col-h-2 {
        background: #131313;
        border-bottom: 1px solid #1e1d1d;
    }

    .bg-b-1 {
        border-bottom: 1px solid #131313;
    }

    .text-sms {
        color: 1px solid #1e1d1d;
    }

    .ico-status {
        border: 3px solid #131313;
    }

    .notification {
        border: 2px solid #131313;
    }

    .btn-refresh {
        background: #2c2c2c;
        color: #9b9b9b;
    }

    .form-select {
        border: none;
        background-color: #272727;
        color: #767676;
    }

    .check {
        text-shadow: 2px 2px 0 #1f1f1f, -2px -2px 0 #1f1f1f;
    }

    .input-group-text {
        border: none;
        background: #272727;
        color: #767676;
    }

    .form-control {
        border: none;
        background: #272727;
        color: #767676;
    }

    .form-select-mat {
        border: none;
        background: #272727;
        color: #767676;
    }

    .mat-f-dark {
        background: #131313;
    }

    .form-control:focus {
        border: none;
        background: #272727;
        color: #fff;
    }

    .form-control::placeholder {
        color: #767676;
    }

    .ico-menu {
        fill: #9b9b9b;
        cursor: pointer;
    }

    .cnt-chat-user {
        background: #1f1f1f;
    }

    .btn-points {
        color: #9b9b9b;
        border: 1px solid #9b9b9b;
    }

    .ico-absolute-2 {
        text-shadow: -1px 2px 0 #131313, 1px -2px 0 #131313;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: #2c2c2c;
    }

    .border {
        border: 1px solid #2c2c2c !important;
    }

    .border-bottom {
        border-bottom: 1px solid #767676 !important;
    }

    .border-top {
        border-top: 1px solid #767676 !important;
    }

    .border-end {
        border-right: 1px solid #767676 !important;
    }

    .border-start {
        border-left: 1px solid #767676 !important;
    }

    .border-start-3 {
        border-left: 3px solid #767676 !important;
    }

    .border-gris {
        border: 1px solid #2c2c2c;
    }

    .border-g-b {
        border-bottom: 1px solid #2c2c2c !important;
    }

    .border-p-b {
        border-bottom: 1px solid #43c1fc !important;
    }

    .border-dashed {
        border: 1px dashed #3e3e3e;
    }

    .bg-gris {
        background: #272727;
    }

    .btn-plus {
        border: 3px solid #9b9b9b;
        color: #9b9b9b;
    }

    .text-1 {
        color: #fff;
        fill: #fff;
    }

    .text-2 {
        color: #767676;
        fill: #767676;
    }

    .text-3 {
        color: #767676;
        fill: #767676;
    }

    .text-4 {
        color: #9b9b9b;
        fill: #9b9b9b;
    }

    .text-5 {
        color: #3e3e3e;
    }

    .text-6 {
        color: #767676;
    }

    .text-7 {
        color: #ffffff;
    }

    .text-8 {
        color: #ffffff;
    }

    .text-red {
        color: #F85050;
    }

    .bg-gris-1 {
        background-color: #424242;
    }

    .bg-gris-2 {
        background-color: #272727;
    }

    .bg-gris-3 {
        background-color: #272727;
    }

    .bg-gris-10 {
        background-color: #2998ff;
    }

    .bg-gris-light {
        background-color: #f4faff;
    }

    .bg-gris-light-dark {
        background-color: #d1edff;
    }

    .ico-theme-l {
        color: #767676;
        border: 1px solid #e3ecf1;
    }

    .ico-theme-d {
        color: #ffffff;
        border: 1px solid #03c0fc;
    }

    .offcanvas {
        background-color: #1f1f1f;
    }

    .page-item.active .page-link {
        background-color: #03c0fc;
    }

    .page-item .page-link {
        background-color: #1f1f1f;
    }

    .page-item .page-link {
        border: 1px solid #3e3e3e;
    }

    .status-circle {
        border: 3px solid #1f1f1f !important;
    }

    .profile-tag {
        color: #ffffff !important;
    }

    .profile-tag:hover {
        color: #03c0fc !important;
    }

    .dropdown-1:after {
        border-bottom-color: #191919 !important;
    }

    .dropdown-menu {
        background: #191919 !important;
    }
}

.ligth-theme {
    @include angular-material-theme($front-osmos-ligth-theme);

    .body {
        background: #fff;
    }

    .navbar {
        background: #fff;
    }

    .p-h,
    .accordion-button::after {
        color: #7ea2b6;
    }

    .ico-h,
    .text-h {
        color: #fff;
    }

    .add-more-card {
        background: #1f35;
    }

    .col-h {
        background: #113d55;
        border-top: 3px solid transparent;
    }

    .bg-col-h {
        background: #113d55;
    }

    .cnt-collapse {
        background: #0d3348;
    }

    .ico-h {
        background: #03c0fc;
    }

    .switch-button-checkbox:checked+.switch-button-label .switch-button-label-span2 {
        color: #fff;
    }

    .switch-button-checkbox+.switch-button-label .switch-button-label-span {
        color: #fff;
    }

    .switch-button-checkbox:checked+.switch-button-label .switch-button-label-span {
        color: #113d55;
    }

    .switch-button-checkbox+.switch-button-label .switch-button-label-span2 {
        color: #113d55;
    }

    .switch-button,
    .cnt-dashboard {
        background: #fff;
        border: 1px solid #f4f4f4;
    }

    .dropdown-menu {
        background: #fff;
        border: 1px solid #e3ecf1;
    }

    .dropdown-1:after {
        border: solid transparent;
        border-bottom-color: #ffffff;
        border-width: 13px;
        margin-right: 0px;
    }

    .dropdown-1:before {
        border: solid transparent;
        border-bottom-color: #e3ecf1;
        border-width: 15px;
        margin-right: -2px;
    }

    .bg-0::after {
        border-bottom-color: #ecf1f4;
    }

    .cnt-ico {
        background: #03c0fc;
        color: #fff;
    }

    .cnt-sms {
        border-left: 1px solid #e3ecf1;
    }

    .col-h-2 {
        background: #fff;
        border: 1px solid #e3ecf1;
    }

    .bg-col-h-2 {
        background: #fff;
        border-bottom: 1px solid #e3ecf1;
    }

    .bg-b-1 {
        border-bottom: 1px solid #e3ecf1;
    }

    .text-sms {
        color: #e3ecf1;
    }

    .ico-status {
        border: 3px solid #fff;
    }

    .notification {
        border: 2px solid #fff;
    }

    .btn-refresh {
        background: #f0f8fd;
        color: #b6c9d4;
    }

    .form-select {
        border: 1px solid #cbdbe4;
        color: #113d55;
    }

    .check {
        text-shadow: 2px 2px 0 #ffff, -2px -2px 0 #ffff;
    }

    .border-up {
        border-top: 1px solid #e3ecf1;
    }

    .input-group-text {
        border: 1px solid #cbdbe4;
        background: #fff;
        color: #cbdbe4;
    }

    .form-control {
        border: 1px solid #cbdbe4;
        color: #113d55;
    }

    .form-control:focus {
        color: #113d55;
    }

    .form-control::placeholder {
        color: #b5c9d4;
    }

    .form-select::placeholder {
        color: #b5c9d4;
    }

    .ico-menu {
        fill: #cbdbe4;
        cursor: pointer;
    }

    .cnt-chat-user {
        background: #8bd1ff;
    }

    .btn-points {
        color: #fff;
        border: 1px solid #fff;
    }

    .ico-absolute-2 {
        text-shadow: -1px 2px 0 #113d55, 1px -2px 0 #113d55;
    }

    // thead,
    // tbody,
    // tfoot,
    // tr,
    // td,
    // th {
    //     border-color: #e3ecf1;
    // }

    .border {
        border: 1px solid #e3ecf1 !important;
    }

    .border-bottom {
        border-bottom: 1px solid #e3ecf1 !important;
    }

    .border-top {
        border-top: 1px solid #e3ecf1 !important;
    }

    .border-end {
        border-right: 1px solid #e3ecf1 !important;
    }

    .border-start {
        border-left: 1px solid #e3ecf1 !important;
    }

    .border-start-3 {
        border-left: 3px solid #e3ecf1 !important;
    }

    .border-gris {
        border: 1px solid #cbdbe4;
    }

    .border-g-b {
        border-bottom: 1px solid #cbdbe4 !important;
    }

    .border-p-b {
        border-bottom: 1px solid #43c1fc !important;
    }

    .border-dashed {
        border: 1px dashed #b6c9d4;
    }

    .bg-gris {
        background: #f8fafc;
    }

    .btn-plus {
        border: 3px solid #b6c9d4;
        color: #b6c9d4;
    }

    .text-1 {
        color: #113d55;
        fill: #113d55;
    }

    .text-2 {
        color: #c1d0d9;
        fill: #c1d0d9;
    }

    .text-3 {
        color: #92a0a8;
        fill: #92a0a8;
    }

    .text-4 {
        color: #b5c9d4;
        fill: #b5c9d4;
    }

    .text-5 {
        color: #cbdbe4;
        fill: #cbdbe4;
    }

    .text-6 {
        color: #92A0A8;
        fill: #92A0A8;
    }

    .text-7 {
        color: #fff;
        fill: #fff;
    }

    .text-8 {
        color: #364F6B;
    }

    .text-red {
        color: #F85050;
    }

    .bg-gris-1 {
        // background-color: #ffffff;
    }

    .bg-gris-2 {
        background-color: #e3ecf1;
    }

    .bg-gris-3 {
        background-color: #b5c9d4;
    }

    .bg-gris-10 {
        background-color: #2998ff42;
    }

    .bg-gris-light {
        background-color: #f4faff;
    }

    .bg-gris-light-dark {
        background-color: #d1edff;
    }

    .bg-celeste {
        background-color: #e7f5ff;
    }

    .bg-celeste-2 {
        background-color: #eef8ff;
    }

    .body-2 {
        background: #ecf1f4;
    }

    .ico-theme-l {
        color: #ffb800;
        border: 1px solid #03c0fc;
    }

    .ico-theme-d {
        color: #b6c9d4;
        border: 1px solid #e3ecf1;
    }

    .acd-btn-2,
    .acd-btn-2::before {
        color: #113d55;
    }

    .acd-btn-3,
    .acd-btn-3::after {
        color: #113d55;
    }

    .offcanvas {
        background-color: #fff;
    }
}

// @include angular-material-theme($front-osmos-ligth-theme);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";

html,
body {
    height: 100%;
}


.hidden {
    display: none !important;
}

.modal-content {
    border-radius: 41px;
}

.border-r4 {
    border-radius: 40px;
}

.font-xx {
    font-size: xx-large;
}

.font-small {
    font-size: small;
}

.font-large {
    font-size: large;
}

.btn-osmos {
    background: #0099ff;
    color: white;
    max-width: 160px;
}

.btn-osmos-2 {
    background: #0099ff;
    color: white;
    max-width: 250px;
}

.btn-osmosModal {
    background: #0099ff;
    color: white;
    max-width: 220px;
}

.btn.btn-light {
    background: rgba(3, 192, 252, 0.16);
    border-radius: 5px;
    color: #03c0fc;
}

.bg-osmos {
    background-color: #0099ff !important;
}

.text-osmos {
    color: #0099ff;
}

.border-osmos {
    border-color: #0099ff !important;
}

.btn-osmos:hover {
    background: #0099ff;
}

.btn-osmos:active {
    background: #0082d8;
}

.btn-osmos-transparent {
    background: transparent;
    color: #0099ff;
    border-color: #0099ff;
}

.btn-osmos-transparent:hover {
    background: #0099ff;
    color: white;
}

.btn-osmos-transparent:active {
    background: #0099ff;
    color: white;
}

.btn-outline {
    border: 1px solid #03C0FC;
    color: #03C0FC;
    border-radius: 5px;
}
.btn-outline:hover {
    background-color: #03C0FC;
    color: #fff;
}

.btn-greenoutline {
    border: 1px solid #22bc31;
    color: #22bc31;
    border-radius: 5px;
}
.btn-greenoutline:hover {
    background-color: #22bc31;
    color: #fff;
}

.btn-wramoutline {
    border: 1px solid #fbc70e;
    color: #fbc70e;
    border-radius: 5px;
}
.btn-wramoutline:hover {
    background-color: #fbc70e;
    color: #fff;
}


.btn-outline1 {
    border: 1px solid #9b9b9b;
    color: #9b9b9b;
    border-radius: 5px;
}
.btn-outline1:hover {
    background-color: #9b9b9b;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}
.align-items-right {
    align-items: right;
}

.br-50 {
    border-radius: 50%;
}

.br-5 {
    border-radius: 15px;
}

.br-05 {
    border-radius: 5px !important;
}

.br-10 {
    border-radius: 10px;
}

.br-0 {
    border-radius: 0px;
}

.bw-2 {
    border-width: 5px !important;
}

/* colores fijos */

.bg-none {
    background: transparent !important;
}

.icon-color-1 {
    color: #03C0FC
}

.bg-1 {
    background-color: #43c1fc;
}

.bg-1-2 {
    background-color: #1cadff;
}

.bg-2 {
    background-color: #22bc31;
    color: white;
}
.bg-2:hover {
    background-color: #128C1E;
    color: white;
}

.bg-3 {
    background-color: #ffb800;
}



.bg-8 {
    background-color: #fbc70e;
    color: white
}

.bg-8:hover {
    background-color: #ff9f10;
    color: white;
}

.bg-4 {
    background-color: #f85050;
}

.bg-5 {
    background-color: #b6c9d4;
}

.bg-6 {
    background-color: #dbebf3;
}

.bg-7 {
    background-color: #f7f7f8 !important;
}

.bg-00 {
    background-color: #ffffff !important;
}

.bg-active {
    background-color: rgba(46, 155, 255, 15%);
}

.bg-chatting {
    background-color: rgba(237, 144, 228, 10%);
}

.bg-agreement {
    background-color: rgba(26, 221, 221, 12%);
}

.bg-meeting {
    background-color: rgba(255, 166, 32, 13%);
}

.bg-lost {
    background-color: rgba(248, 80, 80, 20%);
}

.bg-won {
    background-color: rgba(34, 188, 49, 20%);
}

.bg-active-dark {
    background-color: rgb(46, 155, 255);
}

.bg-chatting-dark {
    background-color: rgb(237, 144, 228);
}

.bg-agreement-dark {
    background-color: rgb(26, 221, 221);
}

.bg-meeting-dark {
    background-color: rgb(255, 166, 32);
}

.bg-lost-dark {
    background-color: rgb(248, 80, 80);
}

.bg-won-dark {
    background-color: rgb(34, 188, 49);
}

.color-1 {
    color: #43c1fc;
    fill: #43c1fc;
}

.color-1-2 {
    color: #1cadff;
}

.color-2 {
    color: #22bc31;
    fill: #22bc31;
}

.color-3 {
    color: #ffb800;
    fill: #ffb800;
}

.color-4 {
    color: #f85050;
    fill: #f85050;
}

.color-4 {
    color: #f85050;
    fill: #f85050;
}

.color-5 {
    color: #cccccc;
    fill: #cccccc;
}

.color-6 {
    color: #dc3545;
    fill: #dc3545;
}

.color-7 {
    color: #cbdbe4;
    fill: #cbdbe4;
}

.color-8 {
    color: #364f6b;
    fill: #364f6b;
}

.color-00 {
    color: #ffffff;
    fill: #ffffff;
}

.color-active {
    color: #2e9bff !important;
    fill: #2e9bff !important;
}

.color-chatting {
    color: #ed90e4 !important;
    fill: #ed90e4 !important;
}

.color-agreement {
    color: #1adddd !important;
    fill: #1adddd !important;
}

.color-meeting {
    color: #ffa620 !important;
    fill: #ffa620 !important;
}

.border-1 {
    border: 1px solid #43c1fc;
}

// Error Message
.error-message {
    color: #fa4141;
}
.success-message {
    color: #22bc31;
}

.warning {
    color: #ffb800 !important;
}

.state-1 {
    background-color: #03c2fc2f;
    color: #43c1fc;
}

.state-2 {
    background-color: #22bc312a;
    color: #22bc31;
}

.state-3 {
    background-color: #ffb7002f;
    color: #ffb800;
}

.state-4 {
    background-color: #f850502f;
    color: #f85050;
}

.btn-2 {
    border: 1px solid #43c1fc;
    color: #43c1fc;
    fill: #43c1fc;
}

.btn-2:hover {
    border: 1px solid #1cadff !important;
    color: #1cadff !important;
}

.btn-3 {
    border: 1px solid #cbdbe4;
    color: #92a0a8;
    fill: #92a0a8;
}

.btn-3:hover {
    border: 1px solid #dae6ec !important;
    color: #9eabb3 !important;
}

.btn-1 {
    border: 1px solid #43c1fc;
    color: #fff;
    fill: #fff;
    background-color: #43c1fc;
}

.btn-add-1 {
    border: 1px solid #03c0fc;
    color: #fff;
    fill: #fff;
    background-color: #03c0fc;
}

.btn-add-1:hover {
    border: 1px solid #03c0fc !important;
    color: #fff !important;
    fill: #fff !important;
    background-color: #03c0fc !important;
}

.btn-1:hover {
    border: 1px solid #43c1fc !important;
    color: #fff !important;
    background-color: #43c1fc !important;
}

.btn-5 {
    border: 1px solid #b6c9d4 ;
    color: #fff;
    background-color: #b6c9d4;
}

.btn-5:hover {
    border: 1px solid #94a3ac !important;
    color: #fff !important;
    background-color: #94a3ac !important;
}

.btn-gris {
    background-color: #b5c9d4;
    color: #fff;
    border: 1px solid #b5c9d4;
}

.btn-gris:hover {
    background-color: #a5b9c4 !important;
    border-color: #a5b9c4 !important;
    color: #fff !important;
}

.btn.btn-white {
    background-color: #ffffff;
    border: 1px solid #e3ecf1;
    color: inherit;
}

.btn-check:checked+.btn.btn-white {
    background-color: #03c0fc;
    color: #ffffff;
}

//float
.float-right {
    float: right;
}

.float-left {
    float: left;
}

.float-unset {
    float: none;
}

//Text aling
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-muted {
    font-size: 14px;
    color: #a8a8a8 !important;
}

.text-unmuted {
    color: #113d55 !important;
}

// modal
.modal-header {
    display: block;
}

.modal-header .close {
    float: right;
    background: transparent;
    border-color: red;
    color: red;
}

.modal-header,
.modal-footer {
    border: 0px;
}

.notif-success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}

.notif-success .mat-simple-snackbar-action {
    color: red;
}
.notif-success2 {
    color: #ffffff !important;
  background-color: #43c1fc !important;
  border-color: #84d3f8 !important;
}

.notif-success2 .mat-simple-snackbar-action {
    color: red;
}


.notif-error {
    color: #fff !important;
    background-color: red !important;
    border-color: red !important;
}

.notif-error .mat-simple-snackbar-action {
    color: #fff;
}

.cdk-overlay-container {
    z-index: 1060;
}

.pointer {
    cursor: pointer;
}

.error_message {
    color: red;
}


// .form-mat-select {
//     padding: 0.375rem 0.4rem 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #212529;
//     border: 1px solid #ced4da;
//     border-radius: 0.25rem;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
// }

.ligth-theme .mat-form-field-appearance-fill .mat-form-field-flex {
    background: #fff !important;
    background-color: #fff !important;
    padding: 0px;
}

.input_error_message {
    color: Red;
    margin-left: 2%;
    font-size: small;
    width: inherit;
    text-align: left;
    display: block;
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.show {
    display: block;
}

.hide {
    display: none !important;
}

.d-flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.min-w-100 {
    min-width: 100% !important;
}

.max-w-50 {
    max-width: 50% !important;
}

.max-w-80 {
    max-width: 80% !important;
}

.min-h-100 {
    min-height: 100% !important;
}

.form-control:focus,
.form-select:focus {
    color: #212529;
    border-color: #cbdbe4 !important;
    outline: 0;
    box-shadow: none !important;
}

.text-justify {
    text-align: justify;
}

.side-menu-nav-tabs {
    border: none !important;
}

.tab-menu {
    border: 2px solid #cbdbe4;
    background: #fff;
    color: #cbdbe4 !important;
    fill: #cbdbe4 !important;
}

.tab-menu-left {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: none;
}

.tab-menu-right {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: none;
}

.tab-menu-left.active {
    border-right: none;
}

.tab-menu-right.active {
    border-right: none;
}

.tab-menu.active {
    background: #03c0fc;
    border: 2px solid #03c0fc;
    fill: #fff !important;
    color: #fff !important;
}

.t-ok-tags {
    text-decoration: none;
}

.br-color-1 {
    border-color: #ced4da;
}

.br-color-2 {
    border-color: #cbdbe4;
}

.ph-1::placeholder {
    color: #cbdbe4 !important;
}

//Upload files
.upload-file+label figure {
    width: 130px;
    height: 130px;
    background: rgba(3, 192, 252, 0.07);
    border: 1px solid #03c0fc;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
}

.upload-file+label figure .upload-icon {
    width: 40px;
    height: auto;
    margin-top: 25px;
}

.upload-logo-text {
    color: #03c0fc;
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px;
}

.profile_photo_container {
    max-width: 130px;
}

.bg-color-blue {
    background-color: #2686e3;
    background-size: cover;
    height: 100vh;
    padding: 70px 50px 130px 50px;
    position: relative;
    display: flex;
    justify-content: center;
}

.j-c-center {
    justify-content: center !important;
}

.j-c-start {
    justify-content: start !important;
}

.j-c-end {
    justify-content: end !important;
}

.setup-box {
    max-width: 500px;
    margin: 0 auto;
}

// label {
//     display: block;
//     font-size: 14px;
//     margin-top: 20px;
// }
.light-shadow {
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.form-max-width {
    max-width: 500px;
}

.form-max-width-1 {
    max-width: 450px;
}

.form-max-width-2 {
    max-width: 500px;
}

.form-max-width-3 {
    max-width: 550px;
}

.form-max-width-4 {
    max-width: 600px;
}

.form-max-width-5 {
    max-width: 700px;
}

.main-form {
    margin-left: 10%;
}

.sign-title {
    font-size: 2.25rem !important;
    font-weight: 500 !important;
}

.input-group-text {
    background-color: #ffffff;
    border: 1px solid #e3ecf1;
    font-size: inherit;
}

.logo {
    max-width: 150px;
}

.mxw-100 {
    max-width: 100px;
}

.mxw-200 {
    max-width: 200px;
}

.mxw-600 {
    max-width: 100px;
}

.minh-400 {
    min-height: 400px;
}

.left-middle-abs {
    top: calc(50% - 122px);
    left: calc(50% - 92px);
    width: 194px;
    height: 258px;
}


.ob-fit-cover {
    object-fit: cover;
    width: 100% !important;
    height: auto;
}


.password-need ul {
    list-style-type: none;
}

//Heights
.h-0 {
    height: 0px !important;
}
.h-50 {
    height: 50px !important;   
}
.h-40 {
    height: 40px !important;
}

.height-100 {
    height: 100px;
}

//Widths
.w-0 {
    width: 0px !important;
}

.w-100 {
    width: 100% !important;
}

.w-90 {
    width: 90% !important;
}

.w-80 {
    width: 80% !important;
    max-width: 80%;
    ;
}

.w-70 {
    width: 70% !important;
}

.w-60 {
    width: 60% !important;
}
.w-500 {
    width: 500px !important;
}

.w-400 {
    width: 400px !important;
}

.w-470 {
    width: 470px !important;
}

.w-600 {
    width: 600px !important;
}

.w-650 {
    width: 650px !important;
}


//Font Sizes
.fs-2 {
    font-size: 20px !important;
}
.fs-7 {
    font-size: 13.5px !important;
}
.fs-10 {
    font-size: 10px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}

.fs-4-1 {
    font-size: calc(1rem + 0.1vw) !important;
}



.btn-round {
    border: 1px solid #03c0fc !important;
}

.border-l {
    border-left: 3px solid #03c0fc !important;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.w-b-all {
    word-break: break-all !important;
}

.h-150 {
    height: 150px !important;
}


.tag-input {
    background-color: #E6F9FF !important;
    border-radius: 3px !important;
    padding: 0.25rem !important;
    color: #03C0FC;
}

.tag-input a {
    color: #364F6B !important;
}

.mat-chip {
    font-weight: normal;
}

.filter-input-bg {
    background-color: #03c0fc !important;
}

.filter-input-bg a {
    color: #ffffff;
}

.filter-input {
    color: #03c0fc;
    border-radius: 3px !important;
    padding: 0.25rem !important;
}

.ok-tags {
    display: none;
}


.example-chip-list .mat-form-field-infix {
    width: 100% !important;
}

.btn:focus {
    box-shadow: none !important;
}

// mat-progress-bar.my-color .mat-progress-bar-fill::after {
//     background-color: green;
// }

mat-progress-bar.my-color .mat-progress-bar-fill::after {
    background-color: #03c0fc;
}

mat-progress-bar.my-color .mat-progress-bar-buffer {
    background: #03c2fc41;
}

// use this as global dialog style
.mat-dialog-container {
    border-radius: 45px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    // padding: 18px!important;
    padding: 22px 22px 30px !important;
}


.max-width-1 {
    max-width: 1600px;
}

.page-link:focus {
    box-shadow: none;
}

.vertical-align-baseline {
    vertical-align: baseline !important;
}

.select-icon-img {
    max-width: 26px;
    max-height: 26px;
}

.select-icon-img-2 {
    max-width: 35px;
    max-height: 35px;
}

.social-modal .form-select-mat {
    line-height: 2 !important;
}

.ligh-main {
    color: #03c0fc !important;
}

.border-none {
    border: none !important;
}

.div-boeder-none {
    border: none !important;
}

.p-t-3 {
    padding-top: 1rem;
}

.p-l-20 {
    padding-left: 20px;
}

a {
    text-decoration: none;
}

.my-t-3 {
    margin-top: 1rem;
}

.my-t-b-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.max-width-450 {
    max-width: 450px;
}

.mt-21 {
    margin-top: 0.6rem;
}

.mb-10 {
    margin-bottom: 10px;
    ;
}

.wh20 {
    width: 20px;
    height: 20px;
}
.mideum_checkbox {
    width: 40px!important;
    height: 24px;
}

/*input switch*/
// .input-switch {
//     background-position: -4px;
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
//     background-size: 28px 28px;
//     box-shadow: -0.10rem 0.05rem 0.25rem rgb(0 0 0 / 20%);
//     background-color: #B5C9D4;
//     border: none;
// }

// .input-switch:focus {
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
// }

// .form-switch .input-switch:checked {
//     background-position: 18.5px;
//     border: none;
//     box-shadow: 0.10rem 0.05rem 0.25rem rgb(0 0 0 / 20%);
//     background-color: #43C1FC;
// }

// .form-switch .input-switch {
//     width: 43px;
//     height: 20px;
// }

.display-inline {
    display: inline-block;
}

.modal-backdrop {
    z-index: 1000;
}


.height-select {
    height: 38px;
}

.add-btn-link {
    cursor: pointer;
}

// .date_inpu_task
// {
//     height: 38px;
// }

.remove_bullets {
    list-style-type: none;
}

table tr th {
    cursor: pointer;
}

.l-h-60 {
    line-height: 60px;
}

.l-h-30 {
    line-height: 30px;
}

.max-width-800 {
    max-width: 800px;
}

.vertical-align-center {
    position: absolute;
    top: 32%;
}

.bold {
    font-weight: bold;
}

.font-weight-500 {
    font-weight: 500;
}

.filter-dropdown {
    display: none !important;
}

.filter-dropdown.show {
    display: block !important;
}

// #mat-select-0-panel {
//     overflow: hidden !important;
// }
// .cdk-drag-placeholder {
    // background: #e2ffbd;
// }

.ligth-theme .mat-chip.mat-standard-chip .mat-chip-remove {
    color: #A4B5BE;
    opacity: 1;
}

.ligth-theme .filter-input-bg .mat-chip-remove {
    color: #ffffff !important;
}

.ligth-theme .mat-chip.mat-standard-chip::after,
.mat-chip.mat-standard-chip::after {
    background: transparent;
}

// .filter-section-height {
//     max-height: 150px;
//     ;
// }

// .mat-expansion-panel:not([class*=mat-elevation-z]),
// .ligth-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
//     box-shadow: none;
//     border-bottom: 1px solid #CBDBE4;
// }

// .mat-expansion-panel-content,
// .mat-expanded .mat-expansion-panel-header,
// .ligth-theme .mat-expansion-panel-header.mat-expanded {
//     background: rgba(227, 236, 241, 0.45) !important;
// }

.ligth-theme .mat-expansion-panel-header.mat-expanded {
    height: 54px;
}

// .mat-expansion-panel-header.mat-expanded {
//     background: rgba(227, 236, 241, 0.45);
//   }

.filter-popup-width {
    width: 500px;
}

// .mat-accordion .mat-expansion-panel:last-of-type {
//     border-radius: 0px !important;
// }

.w-100-per {
    width: 100%;
}

// .form-control:disabled,
// .form-control:read-only {
//     background-color: #ffffff;
// }

.ngx-slider .ngx-slider-pointer {
    background-color: #03C0FC !important;
    height: 16px !important;
    width: 16px !important;
    top: -15px !important;
}

.ngx-slider .ngx-slider-selection {
    background: #03C0FC !important;
}

.ngx-slider .ngx-slider-pointer:after {
    display: none !important;
}

.ngx-slider .ngx-slider-bubble {
    bottom: -22px !important;
    ;
}

.ngx-slider {
    margin: 10px 0 10px !important;
}

.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
    opacity: 0 !important;
}

.ngx-slider .ngx-slider-bubble {
    font-size: 12px !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
    padding-top: 7px !important;
    height: 18px !important;
}

.page-item.active .page-link {
    z-index: 1;
}

// .mat-expansion-indicator::after {
//     border: 0px;
//     content: "+" !important;
//     border-style: none;
//     border-width: 0px !important;
//     transform: none !important;
//     font-size: 20px;
//     color: #03C0FC !important;
// }

// .mat-expanded .mat-expansion-indicator::after {
//     content: "-" !important;
// }


.backdrop {
    background-color: #000;
    opacity: 0.5;
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0px;
    left: 0px;
}


.input-group-icon {
    right: 138px;
    top: 8px;
    z-index: 10;
}

.filter-btn {
    height: 36px;
}

// use for minimize material input form height
.mat-form-field-flex>.mat-form-field-infix {
    padding: 7px 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0.3em 0.75em !important;
}

//  .custom-modalbox .mat-dialog-container {
//     border-radius: 20px !important;
//     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
// }

.close-button-succes {
    position: absolute !important;
    right: -38px !important;
    top: -88px !important;
}

.close-button-succes.team {
    right: -23px !important;
    top: -63px !important;
}

.back-color {
    background-color: #43c1fc !important;
    color: #fff !important;
    width: 100%;
    padding: 6px 10px;
    border: 1px solid #43c1fc !important;
    border-radius: 3px;
}

.mat-select-panel-wrap {
    &.multiselectBox {
        margin-left: 29px !important;
    }
}

.min-height-screen {
    height: 100%;
}

.p-1-rem {
    padding: 1rem;
}

.p-2-rem {
    padding: 2rem;
}

.mat-icon {
    text-align: center;
    height: auto;
    width: auto;
}

.mat-icon i {
    font-size: 18px;
}

.mat-form-field {
    width: 100%;
}

.m-h-dragdrop {
    min-height: 300px;
}

.small-round-plus-btn {
    width: 30px;
    height: 30px;
    font-size: 20px;
}

.cursor-move {
    cursor: move;
}

.rounded-circle-image {
    height: 35px;
    width: 35px;
}

.w-28 {
    width: 28px;
}

.overflow-hidden {
    overflow: hidden;
}

.task-list-dropdown .mat-select-arrow {
    color: #fff;
}

.ml-20 {
    margin-left: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.max-height-calender {
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
}



//Priority Colors
.priority-color-1 {
    color: #1CADFF;
}

.priority-color-2 {
    color: #FFB800;
}

.priority-color-3 {
    color: #F85050;
}

.priority-color- {
    color: #92A0A8;
}


.opactity-0 {
    opacity: 0;
}


.ck.ck-editor__editable_inline {
    height: 160px;
}

.custom-multiple-select-dd-container {
    margin-left: 30px;
    min-width: 105% !important;
}

.icon_img_library {
    width: 30px;
    padding: 3px;
}

.icon_img_big_library {
    width: 50px;
}

.ligth-theme .mat-progress-bar-buffer {
    background-color: #E3ECF1;
}

.ligth-theme .mat-drawer-container {
    background-color: #ECF1F4;
}

.invalid-feedback-date {
    width: 100%;
    margin-top: -14px;
    font-size: 0.7em;
    margin-left: 13px;
}




.mat-chip-list-wrapper {
    margin-bottom: 4px !important;
}



.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}
.my-form-field .mat-form-field-wrapper {
    padding-bottom: 0px;
}
.select-contry-code .mat-select-trigger{
    margin-bottom: 7px;
}
.create-org-model{
    .mat-tab-body-content{
        height: 100%;
        overflow: inherit;
        &::-webkit-scrollbar{
            width: 20px;
        }
    }
}

.f-w-500 {
    font-weight: 500 !important;
}

.cursor-p {
    cursor: pointer;
}

.filter-text {
    color: #03c0fc;
}

.mat-custom-snackbar-class {
    color: #ffffffb3;
    background: #323232;
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.cnt-dashboard-light-blue {
    background: #f2f9fd;
    border: none;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    background-color: #B6C9D4;
    opacity: 1;
    color: #ffffff;
}

.bg-dark-blue:hover {
    background-color: #00A1D3;
}

.bg-dark-blue {
    background-color: #03C0FC;
}

.h{
    margin-left: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}

.card_mastercard {
    height: 35px;
  }
  .card_visa {
    height: 35px;
  }
  .card_discover {
    height: 35px;
  }
  .card_american {
    height: 35px;
  }
  .card_diners {
    height: 35px;
  }
  .card_jcb {
    height: 35px;
  }
  .card_unionpay {
    height: 35px;
  }

  .default-card {
    cursor: pointer;
    border-radius: 15px;
    border-color: #0099ff;
  }
  .other-card {
    cursor: pointer;
    border-radius: 15px;
  }


  //Addon
.addon-description {
    height: 42px;
    overflow: hidden;
}

.fweight{
    font-weight: 600;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
    font-family: inherit;
}

//added or remove toggleColumnDropdown
.toggleColumnDropdown{
    margin-top: 4px !important;
    min-width: auto;
    width: 300px !important;
    font-size: 15px;
}


// use this as global filter btn style
.search_filter{
    width: 483px;
    height: 45px;
    border-radius: 5px;
.filter-btn_module {
    background: #03c0fc;
    border-radius: 0px 5px 5px 0px;
    width:132px;
    height: 45px;
    a {
        color: #fff;
        fill: #fff;
    }
}
}

.templateName .mat-select-placeholder {
    color: #364F6B !important;
}

.templateName .mat-select-arrow {
    color: #364F6B !important;
}

.templateName .mat-select-arrow-wrapper {
  background-image: url('./assets/img/chevron-down-solid-secondary.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
}

.templateFilter .mat-select-placeholder {
    color: #03c0fc !important;
}

.templateFilter .mat-select-arrow {
    color: #03c0fc !important;
}

.templateFilter .mat-select-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin-top: 5px;
}

.templateFilter .mat-select-arrow-wrapper {
    height: 16px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    // margin-top: 9px;
  background-image: url('./assets/img/chevron-down-solid-primary.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.templateFilter .mat-select-trigger{
    // margin-top: 0.5rem ;
}

.custom .ligth-theme .mat-expansion-panel-header-description, .ligth-theme .mat-expansion-indicator::after {
    color: #03C0FC !important;
}
.automationName{
    color: #03C0FC;
}
.closeAutomationModelBtn{
    width: 118px;
    height: 46px;
}
.deleteAutomationModelBtn{
    width: 144px;
    height: 46px;
}
.csv-spinner {
    .container-spinner {
        position: fixed!important;
    }
}
.cursor-pointer-only {
    cursor: pointer !important;
}
::ng-deep.custom-tooltip {
    background: #84d3f8 !important;
  }
  ::ng-deep.helpText-tooltip {
    background: rgba(97, 97, 97, 0.9) !important;
  }